import React, { useEffect, useState } from 'react';
import { Beyond, IdRegistration, Installation, Status } from '../../../common/types';
import { RES_TYPES, useFetch } from '../../../hooks/useFetch';
import { generateDOI, generatePrefix } from './DOIComponent';
import { generateBeyond } from './VendorComponent';
import { FaSave } from 'react-icons/fa';
import { generateIP } from './IPComponent';

type SaveProps = {
  isBatch: boolean;
  isOld: boolean;
  batchAmount: number;
  registration: IdRegistration;
  registrations: IdRegistration[];
  refresh: () => void;
};

export const SaveComponent = ({isBatch, isOld, batchAmount, registration, registrations, refresh}: SaveProps) => {
  const { res: resPost, fetchData: postRegistration } = useFetch('/api/id-registration', {
    method: 'POST',
    lateFetch: true,
    resType: RES_TYPES.empty,
    defaultValue: undefined,
  });
  const {
    res: resPut,
    fetchData: putRegistration,
    setUrl: setPutUrl,
  } = useFetch('', {
    method: 'PUT',
    lateFetch: true,
    resType: RES_TYPES.empty,
    defaultValue: undefined,
  });
  const [saveable, setSaveable] = useState<boolean>(false);

  useEffect(() => {
    const generalCondition = (!!registration.project || !!registration.bouwdeel || isOld) &&
      (!registration.beyond || registration.beyond.network_number > -1) &&
      registration.floor !== '' &&
      !!registration.vendor &&
      !!registration.vendortype &&
      (registration.process !== '' || isOld || registration.installation !== Installation.NAREGELING);
      const batchCondition = isBatch || (registration.doi > -1 && registration.ip_address !== '' && registration.installation !== '');
    setSaveable(generalCondition && batchCondition);
  }, [registration, isOld]);

  useEffect(() => {
    if (registration.id) {
      setPutUrl(`/api/id-registration/${registration.id}`);
    }
  }, [registration.id, setPutUrl]);

  useEffect(() => {
    if ((resPut && resPut.ok) || (resPost && resPost.ok)) {
      refresh();
    }
    if (resPut && !resPut.ok || resPost && !resPost.ok) {
      alert('De registratie kon niet worden opgeslagen.');
    }
  }, [resPost, resPut]);

  const addRegistration = () => {
    let data: IdRegistration | IdRegistration[];
    if (isBatch) {
      data = generateBatch();
      if(data.length === 0) {
        return;
      }
    } else {
      data = registration;
    }

    if (registration.id) {
      putRegistration(JSON.stringify(data));
    } else {
      postRegistration(JSON.stringify(data));
    }
  };

  const generateBatch = () => {
    const bouwdeel = registration.bouwdeel || registration.project?.bouwdeel;
    if (!bouwdeel) {
      return [];
    }
    const registrationsToCheck = registrations.filter(reg => reg.parent_beyond?.id === registration.parent_beyond?.id);
    const ips = generateIP(registration, registrationsToCheck, batchAmount);
    const dois = generateDOI(generatePrefix(registration), bouwdeel, registrations, batchAmount);
    const beyonds = registration.vendortype && registration.vendortype.name === 'Beyond'
      ? generateBeyond(registrations.map(reg => reg.beyond).filter((beyond): beyond is Beyond => !!beyond), batchAmount)
      : Array(batchAmount).fill(undefined);
    if (ips.length !== batchAmount) {
      alert('Niet genoeg IP adressen beschikbaar.');
      return [];
    }
    if (dois.length !== batchAmount) {
      alert('Niet genoeg DOI nummers beschikbaar.');
      return [];
    }
    if (beyonds.length !== batchAmount) {
      alert('Niet genoeg netwerknummers beschikbaar.');
      return [];
    }
    return ips.map((ip, index) => ({
      ...registration,
      ip_address: ip,
      doi: dois[index],
      beyond: beyonds[index],
      status: Status.REGISTERED
    }));
  };

  return (
    <FaSave className={'update-icon' + (!saveable ? ' disabled' : '')} onClick={() => addRegistration()} title="Opslaan" data-testid="save-button" />
  )
};