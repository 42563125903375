import React, { useEffect, useState } from 'react';
import { FiRefreshCcw } from 'react-icons/fi';
import { IdRegistration } from '../../../common/types';

/***
 * IPComponent
 * TODO: IP address generation
 *  - IP address should take beyond into account
 * TODO: IP address validation
 *  - IP address should be unique under the same beyond (also in case beyond is not set)
 */

export interface IPProps {
  selectedReg?: string;
  registration: IdRegistration;
  registrations: IdRegistration[];
  updateRegistration: (name: string, value: string | number) => void;
}

export const IPComponent = ({ selectedReg, registrations, registration, updateRegistration }: IPProps) => {
  const [localIpAddress, setLocalIpAddress] = useState<string>('');
  const [gatewayObject, setGatewayObject] = useState<string>('');
  const [gatewayRegistrations, setGatewayRegistrations] = useState<IdRegistration[]>([]);

  useEffect(() => {
    setLocalIpAddress('');
    setGatewayObject('');
  }, [selectedReg]);

  useEffect(() => {
    setGatewayRegistrations(registrations.filter(reg => reg.parent_beyond?.id === registration.parent_beyond?.id));
  }, [registration.parent_beyond, registrations]);

  useEffect(() => {
    if (registration.gateway) {
      if (!registration.ip_address || gatewayObject && registration.gateway !== gatewayObject) { //If gateway changed
        setLocalIpAddress(registration.gateway.substring(0, registration.gateway.lastIndexOf('.') + 1));
      }
      setGatewayObject(registration.gateway);
    } else {
      setLocalIpAddress('');
    }
  }, [registration.gateway]);

  useEffect(() => {
    if(!localIpAddress) {
      return;
    }
    const parts = localIpAddress.split('.');
    const correct =
      localIpAddress !== gatewayObject &&
      gatewayRegistrations.findIndex(reg => reg.ip_address === localIpAddress && reg.id !== selectedReg) === -1 &&
      parts.length === 4 &&
      parts.every(part => !isNaN(+part)) &&
      Number(parts[3]) > 10 &&
      Number(parts[3]) < 255;

    if (correct) {
      updateRegistration('ip_address', localIpAddress);
    } else {
      updateRegistration('ip_address', '');
    }
  }, [localIpAddress, gatewayObject]);

  const autoIP = (registration: IdRegistration) => {
    const ip = generateIP(registration, gatewayRegistrations)[0];
    if (ip) {
      setLocalIpAddress(ip);
    } else {
      alert('Kon geen IP adres vinden');
    }
  };

  return (
    <div className="property">
      <label>
        IP-adres
        {gatewayObject && (
          <FiRefreshCcw title="Autogenereer IP" className="ip-generate update-icon" onClick={() => autoIP(registration)}></FiRefreshCcw>
        )}
      </label>
      <input
        data-testid="ip_address"
        placeholder="Uniek IP-adres"
        className={registration.ip_address === '' ? 'error' : ''}
        type="string"
        name="ip_address"
        value={localIpAddress || registration.ip_address}
        onChange={event => setLocalIpAddress(event.target.value)}
      />
    </div>
  );
};

export const generateIP = (registration: IdRegistration, registrations: IdRegistration[], amount = 1) => {
  const result = [] as string[];
  const gateway = registration.parent_beyond?.gateway || registration.gateway;
  if (!gateway) {
    return result;
  }
  const firstPart = gateway.substring(0, gateway.lastIndexOf('.') + 1);
  const existingIps = [
    ...registrations
      .filter(reg => (reg.ip_address || '').startsWith(firstPart))
      .map(reg => reg.ip_address),
    gateway,
  ];
  [...Array(243).keys()]
    .map(n => n + 11)
    .some(val => {
      if (!existingIps.includes(firstPart + val)) {
        result.push(firstPart + val);
      }
      return result.length === amount;
    });
  return result;
};
